<template>
  <div>
    <div class="flex items-center justify-center">
      <div
        class="w-full"
        :class="{
          'lg:w-4/12': !props.full
        }"
      >
        <div class="flex text-lg font-bold justify-start mb-4 items-center">
          <img v-if="siteIcon" :src="siteIcon" alt="site icon" class="w-8 mr-3">
        </div>
        <form>
          <div class="relative w-full mb-3">
            <label class="block input-label mb-2">
              メールアドレス
            </label>
            <input v-model="email" type="email" class="input-box" placeholder="" required>
          </div>

          <div class="relative w-full mb-3">
            <label class="block input-label mb-2">
              パスワード
            </label>
            <InputPassword v-model="password" />
          </div>

          <div class="text-center mt-6">
            <button class="btn w-full" :disabled="loading" @click="onLogin">
              <LoadingSpinner v-show="loading" />
              <span v-if="loading">loading...</span>
              <span v-else>ログイン</span>
            </button>
          </div>
          <div class="text-sm bg-gray-100 p-6 mt-6">
            <div v-if="!errorMessage" class="">
              ユーザーアカウントでログインします
            </div>
            <div v-else class="text-red-500">
              {{ errorMessage }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import LoadingSpinner from '~/components/icons/LoadingSpinner.vue'
import InputPassword from '~/components/template/InputPassword.vue'

const props = defineProps<{
  full?: boolean
  reLoginPath?: string
}>()

const config = useRuntimeConfig()
const siteIcon = ''
const loading = ref(false)
const email = ref('')
const password = ref('')
const errorMessage = ref('')

async function onLogin (e: Event) {
  loading.value = true
  errorMessage.value = ''

  try {
    const response = await $fetch<{accessToken: string}>(`${config.public.baseUrl}/v1/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        userEmail: email.value.trim(),
        password: password.value.trim()
      }
    })

    if (!response.accessToken) {
      errorMessage.value = 'ログインできません'
      return
    }

    const profile = await $fetch<Profile>(`${config.public.baseUrl}/v1/auth/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${response.accessToken}`
      }
    })

    return useAuth().login({
      ...profile,
      accessToken: response.accessToken
    }, props.reLoginPath)
  } catch (e) {
    errorMessage.value = 'ログインできません'
  } finally {
    loading.value = false
  }
}

</script>
<style lang="scss"></style>
