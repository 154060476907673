function login (user: LoginUser, reLoginPath?: string) {
  user.isLogin = true
  useLoginAccount().value = { ...user }
  useSession().save(user, 1)
  useLoginDialog().value = false
  if (reLoginPath) {
    return useRouter().push(reLoginPath)
  }
  const to = useRoute().redirectedFrom?.path || `/apps/${user.userId}/profile`
  useRouter().push(to)
}

function logout () {
  useLoginAccount().value.isLogin = false
  useSession().clear()
  navigateTo('/login')
}

export default function () {
  return {
    login: (user: LoginUser, reLoginPath?: string) => login(user),
    logout: () => logout()
  }
}
